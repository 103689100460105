<template>
  <b-card-code title="جدول المستخدمين" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- filter -->
        <b-form-group label="فلتر البحث" label-cols-sm="2" label-align-sm="left" label-size="sm" label-for="filterInput"
          class="mb-0">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="ابحث عن .." />
            <b-input-group-append>
              <b-button @click="reset()">
                الغاء الفلتر
              </b-button>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click="gS()">
                <feather-icon icon="FilePlusIcon" class="mr-50" />
                <span class="align-middle">فلتر </span>
              </b-button>
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" v-b-modal.model-a-w>
                <feather-icon icon="FilePlusIcon" class="mr-50" />
                <span class="align-middle">اضافة مستخدم جديد</span>
              </b-button>
              <export-excel style="color:white;padding-top:10px" class="btn btn-default" :data="items"
                :fields="exportFields" worksheet="My Worksheet" name="filename.xls">

                استخراج اكسل

              </export-excel>

            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <b-form-row style="margin-top: 10px">
        <b-col md="4">
          <b-form-group>
            <v-select v-model="search.department_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
              :options="search.departments" placeholder="اختر القسم" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <v-select v-model="search.role_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
              :options="search.roles" placeholder="اختر الصلاحية" />
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-card-body>
    <div class="demo-vertical-spacing">
      <b-progress v-if="precentage !== 0" :value="precentage" max="100" />
    </div>
    <b-table ref="table" striped hover responsive class="position-relative" :per-page="perPage" :items="items"
      :fields="fields">
      <template #cell(action)="action">
        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
          </template>
          <b-col md="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-success" v-b-modal.model-e-w
              @click="eRM(action.item)">
              <feather-icon icon="EditIcon" class="mr-50" />
              <span class="align-middle">تعديل</span>
            </b-button>
          </b-col>
          <b-col md="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger" v-b-modal.modal-center
              @click="dRM(action.item)">
              <feather-icon icon="Trash2Icon" class="mr-50" />
              <span class="align-middle">حذف</span>
            </b-button>
          </b-col>
          <b-col md="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-success" v-b-modal.modal-user-res
            @click="ePM(action.item)">
              <feather-icon icon="Trash2Icon" class="mr-50" />
            </b-button>
          </b-col>
          <b-col md="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-success" v-b-modal.modal-user-uid
            @click="eSM(action.item)">
              <feather-icon icon="EditIcon" class="mr-50" />
              <span class="align-middle">بصمة</span>
            </b-button>
          </b-col>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1">
        <b-form-select id="perPageSelect" v-model="perPage" @change="check()" size="sm" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
        prev-class="prev-item" next-class="next-item" class="mb-0" @change="check()">
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
    <b-modal id="model-e-w" ok-title="تعديل البيانات" centered size="lg" title="تعديل المستخدم" @show="checkModel"
      @ok="uM">
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">اختر البروفايل </label>
              <v-select v-model="editform.teacher_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                :options="teachers" placeholder="اختر البروفايل" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">اختر القسم</label>
              <v-select v-model="editform.department_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
                :options="departments" placeholder="اختر القسم" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">اختر الصلاحية</label>
              <v-select v-model="editform.role_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
                :options="roles" placeholder="اختر الصلاحية" />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <label for="input-valid1">الاسم الكامل:</label>
            <b-form-input id="input-valid1" v-model="editform.name" :state="editform.name.length > 0"
              placeholder="الاسم الكامل" />
          </b-col>

          <b-col md="12">
            <label for="input-invalid1">الايميل:</label>
            <b-form-input id="input-invalid1" v-model="editform.email" :state="editform.email.length > 0"
              placeholder="الايميل" />
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">الباسورد</label>
              <b-input-group>
                <b-form-input v-model="editform.password" id="basic-password" :type="passwordFieldType"
                  placeholder="الباسورد" />
                <b-input-group-append is-text>
                  <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-form-group>
            <b-form-checkbox-group id="checkbox-group-2" v-model="editform.status" name="flavour-2"
              class="demo-inline-spacing">
              <b-form-checkbox value="1"> تفعيل الحساب </b-form-checkbox>
            </b-form-checkbox-group>
            <b-form-checkbox-group id="checkbox-group-2" v-model="editform.profile" name="flavour-2"
              class="demo-inline-spacing">
              <b-form-checkbox value="1"> عمل بروفايل جديد </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal id="model-a-w" ok-title="حفظ البيانات" centered size="lg" title="اضافة مستخدم جديد" @show="checkModel"
      @ok="aNM">
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col md="12">
            <label for="input-valid1">الاسم الكامل:</label>
            <b-form-input id="input-valid1" v-model="form.name" :state="form.name.length > 0"
              placeholder="الاسم الكامل" />
          </b-col>

          <b-col md="12">
            <label for="input-invalid1">الايميل:</label>
            <b-form-input id="input-invalid1" v-model="form.email" :state="form.email.length > 0" placeholder="الايميل" />
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">الباسورد</label>
              <b-input-group>
                <b-form-input v-model="form.password" id="basic-password" :type="passwordFieldType"
                  placeholder="الباسورد" />
                <b-input-group-append is-text>
                  <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-form-group>
            <b-form-checkbox-group id="checkbox-group-2" v-model="form.status" name="flavour-2"
              class="demo-inline-spacing">
              <b-form-checkbox value="1"> تفعيل </b-form-checkbox>
            </b-form-checkbox-group>
            <b-form-checkbox-group id="checkbox-group-2" v-model="selected" name="flavour-2" class="demo-inline-spacing">
              <b-form-checkbox value="profile">
                عمل بروفايل جديد
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </b-form-row>
      </b-form>
    </b-modal>

    <b-modal id="modal-center" centered title="حذف اليوزر" @ok="dM" ok-title="حذف البيانات">
      <b-card-text>
        هل تريد حذف هذه الحقل " {{ this.editform.name }} "
        ?
      </b-card-text>
    </b-modal>
    <b-modal id="modal-user-res" centered title="تحديد مسؤولية" @ok="uPM" ok-title="تحديد مسؤولية قسم">
      <b-card-text>
        <b-col md="12">
          <b-form-group>
            <label for="basic-password">القسم</label>
            <v-select v-model="presence_id" multiple :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
              :options="departments" placeholder="اختر القسم" />
          </b-form-group>
        </b-col>
        {{ res_departments }}
      </b-card-text>
    </b-modal>
    <b-modal id="modal-user-uid" centered title="تحديد رقم البصمة" @ok="uPUM" ok-title="حفظ الرقم">
      <b-card-text>
        <b-col md="12">
          <b-form-input id="input-valid1" v-model="presence_id"
              placeholder="رقم البصمة" />
        </b-col>
      </b-card-text>
    </b-modal>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import store from "@/store";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormCheckboxGroup,
  BForm,
  BFormRow,
  BCol,
  VBModal,
  BBadge,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput, BProgress,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    BForm,
    vSelect,
    BAvatar,
    BBadge,
    BFormRow,
    BDropdown, BProgress,
    BDropdownItem,
    BFormGroup,
    togglePasswordVisibility,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormCheckboxGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BModal,
    VBModal,
    Ripple,
    BCol,
    BFormCheckbox,
    BCardText,
    BFormValidFeedback,
    BFormInvalidFeedback,
  },
  data() {
    return {
      precentage: 0,
      teacher_id: "",
      presence_id:null,
      perPage: 3,
      pageOptions: [3, 5, 10, 100],
      totalRows: 1,
      currentPage: 0,
      filter: null,
      filterOn: [],
      roles: [],
      teachers: [],
      department_id: "",
      search: {
        departments: [],
        department_id: "",
        role_id: ""
      },
      departments: [],
      exportFields: {
        'الاسم الكامل': 'full_name',
        'الايميل': 'email',
        'الصلاحية': 'rule.name',
        'القسم': 'department.ar_name',
        'البروفايل': 'teacher.ar_name',
      },
      exportData: [
        {
          TotalName: "full_name",
          Email: "email",
          Role: "rule.name",
          Department: "department.ar_name",
          Profile: "teacher.ar_name"
        },
      ],
      res_departments: [],
      fields: [
        {
          key: "full_name",
          label: "الاسم الكامل",
        },
        {
          key: "email",
          label: "الايميل",
        },
        {
          key: "rule.name",
          label: "الصلاحية",
        },
        {
          key: "department.ar_name",
          label: "القسم",
        },
        {
          key: "presence_id",
          label: "رقم البصمة",
        },
        { key: "teacher.ar_name", label: "البروفايل " },
        { key: "action", label: "الاجراءات" },
      ],
      items: [],
      form: {
        name: "",
        email: "",
        password: "",
        status: false,
        profile: "",
        department_id: ""
      },
      editform: {
        name: "",
        email: "",
        password: "",
        role_id: "",
        department_id: "",
        teacher_id: "",
        status: false,
        profile: false,
        id: null,
      },
      selected: [],
      selected2: [],
    };
  },
  watch: {
    filter: function (val) {
      if (val == null) {
        this.filter = null;
      }
      this.gS();
    },
    watch: {
      "$route.params.id": {
        handler: function () {
          this.gS();
        },
      },
      "search.department_id": {
        handler: function () {
          console.log("dlkfslflsdflksd")
          if (val != null || val != "") {
            this.gS()
          }
        },

      },
      "editform.role_id": function (val) {
        console.log("dlkfslflsdflksd")
      },
      department_id: function (val) {
        console.log("dlkfslflsdflksd")
        // if (val != null || val != "") {
        //   this.gS()
        // }
      },
      "department_id": function (val) {
        if (val != null && val != "") {
          this.gSM9(), this.gS();
        }
      },
    },
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.ID(), this.gS(), this.gSM(), this.gSM1(), this.gSM2();
  },
  methods: {
    async gSM2() {
      await this.axios
        .get(`since/deparment?take=${this.perPage}&skip=${this.currentPage - 1
          }&select=true`)
        .then((res) => {
          this.departments = [];
          this.search.departments = res.data.items;
          this.departments = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSM1() {
      await this.axios
        .get(`rules?take=100&skip=0`)
        .then((res) => {
          console.log(res)
          this.roles = [];
          this.search.roles = res.data.items;
          this.roles = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSM() {
      await this.axios
        .get(`teachers-name`)
        .then((res) => {
          this.teachers = [];
          this.teachers = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    checkModel() {
      this.selected = [];
      if (this.editform.is_sustainabilty == 1) {
        this.selected.push("is_sustainabilty");
      }
      if (this.editform.is_energy == 1) {
        this.selected.push("is_energy");
      }
    },
    resetCheckboxes() {
      this.selected = [];
    },
    async uM() {
      if (isNaN(this.editform.department_id)) {
        this.editform.department_id = this.editform.department_id["id"]
      }
      if (isNaN(this.editform.role_id)) {
        this.editform.role_id = this.editform.role_id["id"]
      }
      if (isNaN(this.editform.teacher_id)) {
        this.editform.teacher_id = this.editform.teacher_id["id"]
      }
      await this.axios
        .post(`users/${this.editform.id}`, this.editform, {
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
          }
          this.precentage = 0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async aNM() {
      if (this.selected.length > 0) {
        if (this.selected.includes("profile")) {
          this.form.profile = "?profile=true";
        }
      }
      await this.axios
        .post(`auth/registeration${this.form.profile}`, this.form, {
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "update Success",
              "Your model is added successfully"
            );
          }
          this.rF();
          this.precentage = 0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();
    },
    async uPM() {
      let data = new FormData();
      this.res_departments.forEach((element) => data.append("departments[]", element.id));

      await this.axios
        .post(`user/permissions/${this.editform.id}`, data, {
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "update Success",
              "Your model is added successfully"
            );
          }
          this.rF();
          this.precentage = 0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();
    },
    async uPUM() {
      let data = new FormData();
      data.append("presence_id", this.presence_id);

      await this.axios
        .post(`users/setpresence/${this.editform.id}`, data, {
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "update Success",
              "Your model is added successfully"
            );
          }
          this.rF();
          this.precentage = 0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();
    },
    rF() {
      this.form.name = "",
        this.form.email = "",
        this.form.password = "",
        this.form.profile = "",
        this.form.status = false
        this.presence_id=null
    },
    rEF() {
      this.editform.name = "",
        this.editform.email = "",
        this.editform.password = "",
        this.editform.role_id = "",
        this.editform.teacher_id = "",
        this.editform.status = "",
        this.editform.profile = "",
        this.editform.id = ""
    },
    async dM() {
      await this.axios
        .delete(`users/${this.editform.id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    errorToast() {
      this.makeToast(
        "danger",
        "update failed",
        "some thing want wrong, i`m sorry :("
      );
    },
    eRM(item) {
      this.editform.name = item.full_name;
      this.editform.email = item.email;
      this.editform.password = "";
      this.editform.role_id = item.rule_id;
      this.editform.teacher_id = item.teacher_id;
      this.editform.department_id = item.department_id;
      this.editform.status = item.status;
      this.editform.id = item.id;
    },
    ePM(item) {
      this.res_departments = item.res_departments;
      this.editform.id = item.id;
    },
    eSM(item) {
      this.editform.id = item.id;
    },
    dRM(item) {
      this.editform.name = item.full_name;
      this.editform.id = item.id;
    },
    check() {
      setTimeout(() => this.gR(), 1000);
    },
    ID() {
      this.teacher_id = localStorage.getItem("NisourTeacherId");
    },
    async gS() {
      if (isNaN(this.search.department_id) & (this.search.department_id != "")) {
        this.search.department_id = this.search.department_id["id"];
      }
      if (isNaN(this.search.role_id) & (this.search.role_id != "")) {
        this.search.role_id = this.search.role_id["id"];
      }
      await this.axios
        .get(
          `users?take=${this.perPage}&skip=${this.currentPage - 1
          }&teacher_id=${this.teacher_id}&search=${this.filter}&department=${this.search.department_id}&role=${this.search.role_id}&show=${this.$route.params.id}`
        )
        .then((res) => {
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gR() {
      await this.axios
        .get(`users?take=${this.perPage}&skip=${this.currentPage - 1}&show=${this.$route.params.id}`)
        .then((res) => {
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    checkDept() {
      console.log(this.search.department_id)
      console.log(this.department_id)
    },
    reset() {
      this.search.department_id = "",
        this.search.role_id = ""
      this.filter = ""
      this.gS()
    }
  },
};
</script>
